.placeholder[data-v-02e1ca] {
  opacity: .5;
  outline: 1px dotted ;
  padding: 0 5px;
  display: inline-block;
}

.placeholder[data-v-6db07c] {
  opacity: .5;
  outline: 1px dotted ;
  padding: 0 5px;
  display: inline-block;
}

.placeholder[data-v-ddbc47] {
  opacity: .5;
  outline: 1px dotted ;
  padding: 0 5px;
  display: inline-block;
}

.buttons[data-v-bc1dc3] {
  border-top: 1px solid #ccc;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  padding-top: 20px;
  display: grid;
}

button[data-v-bc1dc3] {
  padding: 5px;
}

form[data-v-755f65] {
  height: 100%;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr auto;
  gap: 10px 20px;
  display: grid;
}

textarea[data-v-755f65] {
  border: 1px solid #ccc;
  grid-column: span 2;
  padding: 5px;
}

label[data-v-755f65] {
  align-items: center;
  gap: 20px;
  font-family: arial, sans-serif;
  display: flex;
}

label[data-v-755f65]:first-child {
  grid-column: span 2;
}

label span[data-v-755f65] {
  text-align: right;
  flex: 0 auto;
}

label input[data-v-755f65] {
  border: 1px solid #ccc;
  flex: 1 0 auto;
  padding: 5px;
}

button[data-v-755f65] {
  width: 100%;
  padding: 5px;
  display: inline-block;
}

.fields[data-v-88fb3c] {
  font-family: arial, sans-serif;
}

.fields label[data-v-88fb3c] {
  align-items: stretch;
  gap: 20px;
  display: flex;
}

.fields label + label[data-v-88fb3c] {
  margin-top: 10px;
}

.fields label.position2[data-v-88fb3c] {
  margin-top: -1px;
}

.fields span[data-v-88fb3c] {
  width: 200px;
  text-align: right;
  align-self: center;
}

.fields label.position2 span[data-v-88fb3c] {
  color: #b0b0b0;
  font-size: .9em;
}

.fields input[data-v-88fb3c] {
  border: 1px solid #ccc;
  border-radius: 0;
  flex: 1 0 auto;
  padding: 5px;
}

.fields input[type="file"][data-v-88fb3c] {
  border: none;
  padding: 0;
}

.fields input[type="checkbox"][data-v-88fb3c] {
  appearance: none;
  height: 20px;
  width: 20px;
  flex: 0 auto;
  align-self: center;
}

.fields input[type="checkbox"][data-v-88fb3c]:checked {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAAAAACo4kLRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA1klEQVR42mXIvyvEYQDH8Y9HBsUNyqYu%2F4S6gYiys5DFoCzMbJfBX2A2GW0Kw2XGX6Akg0nuFnHL3fN83lff7sfzvXuNLzEmAZq41t5tOR15WNj9Vensus5wnom%2FfV0QrfyamzonGWX3XdMhNqM0zZo22pgio4v739LyJwlAxYA50EyDSJG0rl9xoi5d9g%2FdL6nyAndB25h%2B3swHrfJV1eIbaZA0pqf0eCxdERlklxOpOqc1wzATHxWFoCfiKIkcaVY7mCwTz0Er76Q8DeunHUyewE%2F56AEvp04RuKIQNAAAAABJRU5ErkJggg%3D%3D") 0 0 / contain no-repeat;
}

.fields input.phone[data-v-88fb3c] {
  margin-left: -20px;
}

.fields input.email[data-v-88fb3c] {
  margin-right: -20px;
}

.fields .preffix[data-v-88fb3c], .fields .suffix[data-v-88fb3c] {
  background: #ccc;
  padding: 5px;
  font-size: .8em;
  display: inline-block;
}

[data-v-e5fb20]:root {
  --red: 255, 0, 0;
  --green: 0, 255, 0;
  --yellow: 255, 255, 0;
}

[data-v-e5fb20], [data-v-e5fb20]:before, [data-v-e5fb20]:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.generator[data-v-e5fb20] {
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: auto;
  gap: 20px;
  padding: 20px;
  font-family: arial, sans-serif;
  display: grid;
}

.col[data-v-e5fb20] {
  border: 1px solid #ccc;
  grid-template-rows: 1fr auto;
  padding: 20px;
  display: grid;
}

.actions[data-v-e5fb20] {
  flex-direction: column;
  display: flex;
}

.actions .instructions[data-v-e5fb20] {
  margin-bottom: 10px;
  line-height: 1.2;
}

.actions .instructions summary[data-v-e5fb20] {
  cursor: pointer;
}

.actions .instructions ol[data-v-e5fb20] {
  margin-left: 15px;
  font-size: .8em;
}

.actions .download[data-v-e5fb20] {
  text-align: right;
  flex: 0 auto;
  order: 2;
  margin-top: auto;
}

.actions button[data-v-e5fb20] {
  margin-right: 20px;
  padding: 5px;
  display: inline-block;
}

.actions .status[data-v-e5fb20] {
  border: 1px solid rgba(var(--yellow), 1);
  background: rgba(var(--yellow), .5);
  flex: 0 auto;
  margin-bottom: 20px;
  padding: 5px;
  font-family: arial, sans-serif;
}

.actions .status.ok[data-v-e5fb20] {
  border-color: rgba(var(--green), 1);
  background: rgba(var(--green), .5);
}

.actions .status.error[data-v-e5fb20] {
  border-color: rgba(var(--red), 1);
  background: rgba(var(--red), .5);
}

.html[data-v-e5fb20] {
  max-width: 100%;
  background: #ccc;
  grid-column: span 3;
  padding: 20px;
  display: block;
  overflow: auto;
}

.notice[data-v-e5fb20] {
  grid-column: span 3;
  font-family: arial, sans-serif;
  line-height: 1.5;
}

/*# sourceMappingURL=index.74d2ac36.css.map */
